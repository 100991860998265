import { getPrimaryColor } from '@utils';
import SvgBuildingsIcon from 'public/assets/Buildings.svg';

export function BuildingsIcon(props) {
  return (
    <svg
      width="25"
      height="26"
      viewBox="0 0 25 26"
      fill="none"
      strokeWidth="0"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.90625 2.875C3.50014 2.875 3.11066 3.03633 2.82349 3.32349C2.53633 3.61066 2.375 4.00014 2.375 4.40625V20.8438H1.5625C1.14829 20.8438 0.8125 21.1795 0.8125 21.5938C0.8125 22.008 1.14829 22.3438 1.5625 22.3438H3.125H14.0625H21.875H23.4375C23.8517 22.3438 24.1875 22.008 24.1875 21.5938C24.1875 21.1795 23.8517 20.8438 23.4375 20.8438H22.625V10.6562C22.625 10.2501 22.4637 9.86066 22.1765 9.57349C21.8893 9.28633 21.4999 9.125 21.0938 9.125H14.8125V4.40625C14.8125 4.00014 14.6512 3.61066 14.364 3.32349C14.0768 3.03633 13.6874 2.875 13.2812 2.875H3.90625ZM21.125 10.6562V20.8438H14.8125V10.625H21.0938C21.102 10.625 21.11 10.6283 21.1158 10.6342C21.1217 10.64 21.125 10.648 21.125 10.6562ZM13.3125 4.40625V9.875V20.8438H3.875V4.40625C3.875 4.39796 3.87829 4.39001 3.88415 4.38415C3.89001 4.37829 3.89796 4.375 3.90625 4.375H13.2812C13.2895 4.375 13.2975 4.37829 13.3033 4.38415C13.3092 4.39001 13.3125 4.39796 13.3125 4.40625ZM6.25 6.78125C5.83579 6.78125 5.5 7.11704 5.5 7.53125C5.5 7.94546 5.83579 8.28125 6.25 8.28125H9.375C9.78921 8.28125 10.125 7.94546 10.125 7.53125C10.125 7.11704 9.78921 6.78125 9.375 6.78125H6.25ZM7.0625 13.7812C7.0625 13.367 7.39829 13.0312 7.8125 13.0312H10.9375C11.3517 13.0312 11.6875 13.367 11.6875 13.7812C11.6875 14.1955 11.3517 14.5312 10.9375 14.5312H7.8125C7.39829 14.5312 7.0625 14.1955 7.0625 13.7812ZM6.25 16.9375C5.83579 16.9375 5.5 17.2733 5.5 17.6875C5.5 18.1017 5.83579 18.4375 6.25 18.4375H9.375C9.78921 18.4375 10.125 18.1017 10.125 17.6875C10.125 17.2733 9.78921 16.9375 9.375 16.9375H6.25ZM16.4375 17.6875C16.4375 17.2733 16.7733 16.9375 17.1875 16.9375H18.75C19.1642 16.9375 19.5 17.2733 19.5 17.6875C19.5 18.1017 19.1642 18.4375 18.75 18.4375H17.1875C16.7733 18.4375 16.4375 18.1017 16.4375 17.6875ZM17.1875 13.0312C16.7733 13.0312 16.4375 13.367 16.4375 13.7812C16.4375 14.1955 16.7733 14.5312 17.1875 14.5312H18.75C19.1642 14.5312 19.5 14.1955 19.5 13.7812C19.5 13.367 19.1642 13.0312 18.75 13.0312H17.1875Z"
        fill={getPrimaryColor()}
      />
    </svg>
  );
}
