import { useContext, createContext, useReducer, Dispatch } from 'react';
import useSafeDispatch from '@hooks/useSafeDispatch';
import { Address } from 'teddly-sdk';
import { StripeCardData } from '../components/Pages/Checkout/hooks';
import { UserAddressType } from '@context/UserAddressContext';

export enum CurrentOpenDialog {
  None = 'none',
  AddAddressForm = 'add-address-form',
  CartConflict = 'cart-conflict',
  EditAddressForm = 'edit-address-form',
  AddPaymentForm = 'add-payment-form',
  EditPaymentForm = 'edit-payment-form',
  PickAddress = 'pick-address-form',
  SelectDelivery = 'select-delivery',
  PickPayment = 'pick-payment-form',
  PickDeliveryTime = 'pick-delivery-time-form',
}

type CheckoutPageState = {
  currentOpenDialog: CurrentOpenDialog;
  selectedShippingAddress: Address;
  selectedBillingAddress: Address;
  selectedCardInfo: StripeCardData;
  addressToEdit?: UserAddressType;
  cardToEdit?: StripeCardData;
  paymentToken?: string;
  shippingAddressLoading?: boolean;
  timeslotDatetime?: Date;
  isAlcoholWarning?: boolean;
  isLoading?: boolean;
  isAgeValid?: boolean;
  errorMessage?: string;
};

type CheckoutPageAction =
  | {
      type: 'openDialog';
      form: CurrentOpenDialog;
      addressToEdit?: UserAddressType;
      cardToEdit?: StripeCardData;
    }
  | { type: 'SET_SHIPPING_ADDRESS'; address: Address }
  | { type: 'SET_BILLING_ADDRESS'; address: Address }
  | {
      type: 'SET_SELECTED_CARD';
      card: StripeCardData;
    }
  | {
      type: 'SET_PAYMENT_TOKEN';
      token: string;
    }
  | {
      type: 'SET_SHIPPING_ADDRESS_LOADING';
      loading: boolean;
    }
  | {
      type: 'SET_TIMESLOT';
      timeslotDatetime: Date;
    }
  | {
      type: 'SET_IS_ALCOHOL_WARNING';
      isWarning: boolean;
    }
  | {
      type: 'SET_IS_AGE_VALID';
      isAgeValid: boolean;
    }
  | {
      type: 'SET_IS_LOADING';
      isLoading: boolean;
    }
  | {
      type: 'SET_ERROR_MESSAGE';
      errorMessage: string;
    };

const initialState: CheckoutPageState = {
  currentOpenDialog: CurrentOpenDialog.None,
  addressToEdit: null,
  selectedShippingAddress: null,
  selectedBillingAddress: null,
  selectedCardInfo: null,
  paymentToken: null,
  shippingAddressLoading: false,
  timeslotDatetime: null,
  isAlcoholWarning: false,
  isLoading: false,
  isAgeValid: false,
  errorMessage: '',
};

type ContextValueType = CheckoutPageState & {
  closeCurrentDialog: () => void;
  openAddAddressForm: () => void;
  openEditAddressForm: (addressToEdit: UserAddressType) => void;
  openAddPaymentForm: () => void;
  openEditPaymentForm: (cardToEdit: StripeCardData) => void;
  openPickAddressForm: () => void;
  openPickPaymentForm: () => void;
  openPickDevlieryTimeForm: () => void;
  openCartCondlictDialog: () => void;
  setSelectedShippingAddress: (address) => void;
  setSelectedBillingAddress: (address) => void;
  setSelectedCard: (card: StripeCardData) => void;
  setPaymentToken: (token: string) => void;
  setShippingAddressLoading: (loading: boolean) => void;
  setTimeslot: (timeslotDate: Date) => void;
  setIsAlcoholWarning: (isWarning: boolean) => void;
  setIsLoading: (isLoading: boolean) => void;
  setIsAgeValid: (isAgeValid: boolean) => void;
  setErrorMessage: (message: string) => void;
};

function checkoutPageReducer(
  state: CheckoutPageState = initialState,
  action: CheckoutPageAction,
): CheckoutPageState {
  switch (action.type) {
    case 'openDialog': {
      return {
        ...state,
        currentOpenDialog: action.form,
        addressToEdit: action.addressToEdit,
        cardToEdit: action.cardToEdit,
      };
    }

    case 'SET_SELECTED_CARD': {
      return {
        ...state,
        selectedCardInfo: action.card,
      };
    }

    case 'SET_SHIPPING_ADDRESS': {
      return {
        ...state,
        selectedShippingAddress: action.address,
      };
    }

    case 'SET_BILLING_ADDRESS': {
      return {
        ...state,
        selectedBillingAddress: action.address,
      };
    }

    case 'SET_PAYMENT_TOKEN': {
      return {
        ...state,
        paymentToken: action.token,
      };
    }

    case 'SET_SHIPPING_ADDRESS_LOADING': {
      return {
        ...state,
        shippingAddressLoading: action.loading,
      };
    }

    case 'SET_TIMESLOT': {
      return {
        ...state,
        timeslotDatetime: action.timeslotDatetime,
      };
    }

    case 'SET_IS_ALCOHOL_WARNING': {
      return {
        ...state,
        isAlcoholWarning: action.isWarning,
      };
    }
    case 'SET_IS_AGE_VALID': {
      return {
        ...state,
        isAgeValid: action.isAgeValid,
      };
    }

    case 'SET_IS_LOADING': {
      return {
        ...state,
        isLoading: action.isLoading,
      };
    }
    case 'SET_ERROR_MESSAGE': {
      return {
        ...state,
        errorMessage: action.errorMessage,
      };
    }

    default:
      return state;
  }
}

const CheckoutPageContext = createContext<ContextValueType>({
  ...initialState,
  closeCurrentDialog: () => null,
  openCartCondlictDialog: () => null,
  openAddAddressForm: () => null,
  openEditAddressForm: () => null,
  openAddPaymentForm: () => null,
  openEditPaymentForm: () => null,
  openPickAddressForm: () => null,
  openPickPaymentForm: () => null,
  openPickDevlieryTimeForm: () => null,
  setSelectedShippingAddress: () => null,
  setSelectedBillingAddress: () => null,
  setSelectedCard: () => null,
  setPaymentToken: () => null,
  setShippingAddressLoading: () => null,
  setTimeslot: () => null,
  setIsAlcoholWarning: () => null,
  setIsLoading: () => null,
  setIsAgeValid: () => null,
  setErrorMessage: () => null,
});

export const CheckoutPageProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [state, dispatch] = useReducer(checkoutPageReducer, initialState);
  const safeDispatch: Dispatch<CheckoutPageAction> = useSafeDispatch(dispatch);

  return (
    <CheckoutPageContext.Provider
      value={{
        ...state,
        closeCurrentDialog: () =>
          safeDispatch({ type: 'openDialog', form: CurrentOpenDialog.None }),
        setSelectedShippingAddress: (address) =>
          safeDispatch({ type: 'SET_SHIPPING_ADDRESS', address }),
        setSelectedBillingAddress: (address) =>
          safeDispatch({ type: 'SET_BILLING_ADDRESS', address }),
        setSelectedCard: (card) =>
          safeDispatch({ type: 'SET_SELECTED_CARD', card }),
        openAddAddressForm: () =>
          safeDispatch({
            type: 'openDialog',
            form: CurrentOpenDialog.AddAddressForm,
          }),
        openEditAddressForm: (addressToEdit: UserAddressType) =>
          safeDispatch({
            type: 'openDialog',
            form: CurrentOpenDialog.EditAddressForm,
            addressToEdit,
          }),
        openAddPaymentForm: () =>
          safeDispatch({
            type: 'openDialog',
            form: CurrentOpenDialog.AddPaymentForm,
          }),
        openEditPaymentForm: (cardToEdit: StripeCardData) =>
          safeDispatch({
            type: 'openDialog',
            form: CurrentOpenDialog.EditPaymentForm,
            cardToEdit,
          }),

        openPickAddressForm: () =>
          safeDispatch({
            type: 'openDialog',
            form: CurrentOpenDialog.PickAddress,
          }),
        openPickPaymentForm: () =>
          safeDispatch({
            type: 'openDialog',
            form: CurrentOpenDialog.PickPayment,
          }),
        openPickDevlieryTimeForm: () =>
          safeDispatch({
            type: 'openDialog',
            form: CurrentOpenDialog.PickDeliveryTime,
          }),
        openCartCondlictDialog: () =>
          safeDispatch({
            type: 'openDialog',
            form: CurrentOpenDialog.CartConflict,
          }),
        setPaymentToken: (token) =>
          safeDispatch({
            type: 'SET_PAYMENT_TOKEN',
            token,
          }),
        setShippingAddressLoading: (loading) =>
          safeDispatch({
            type: 'SET_SHIPPING_ADDRESS_LOADING',
            loading,
          }),
        setTimeslot: (timeslotDate) => {
          safeDispatch({
            type: 'SET_TIMESLOT',
            timeslotDatetime: timeslotDate,
          });
        },
        setIsAlcoholWarning: (isWarning) =>
          safeDispatch({
            type: 'SET_IS_ALCOHOL_WARNING',
            isWarning,
          }),
        setIsAgeValid: (isAgeValid) =>
          safeDispatch({
            type: 'SET_IS_AGE_VALID',
            isAgeValid,
          }),
        setIsLoading: (isLoading) =>
          safeDispatch({
            type: 'SET_IS_LOADING',
            isLoading,
          }),
        setErrorMessage: (errorMessage) =>
          safeDispatch({
            type: 'SET_ERROR_MESSAGE',
            errorMessage,
          }),
      }}>
      {children}
    </CheckoutPageContext.Provider>
  );
};

export const useCheckoutPageContext = (): ContextValueType => {
  const context = useContext(CheckoutPageContext);

  if (!context)
    throw new Error(
      'CheckoutPageContext was used outside of the CheckoutPageProvider',
    );

  return context;
};
