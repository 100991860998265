import { getPrimaryColor } from '@utils';
import SvgHashIcon from 'public/assets/Hash.svg';

export function HashIcon(props) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.2379 3.88429C17.312 3.47676 17.0417 3.08632 16.6342 3.01223C16.2266 2.93813 15.8362 3.20843 15.7621 3.61597L14.9195 8.25013H10.4441L11.2379 3.88429C11.312 3.47676 11.0417 3.08632 10.6342 3.01223C10.2266 2.93813 9.83619 3.20843 9.7621 3.61597L8.91952 8.25013H4.08789C3.67368 8.25013 3.33789 8.58592 3.33789 9.00013C3.33789 9.41434 3.67368 9.75013 4.08789 9.75013H8.64679L7.82861 14.2501H3C2.58579 14.2501 2.25 14.5859 2.25 15.0001C2.25 15.4143 2.58579 15.7501 3 15.7501H7.55589L6.7621 20.116C6.688 20.5235 6.9583 20.9139 7.36584 20.988C7.77337 21.0621 8.16381 20.7918 8.2379 20.3843L9.08048 15.7501H13.5559L12.7621 20.116C12.688 20.5235 12.9583 20.9139 13.3658 20.988C13.7734 21.0621 14.1638 20.7918 14.2379 20.3843L15.0805 15.7501H19.9125C20.3267 15.7501 20.6625 15.4143 20.6625 15.0001C20.6625 14.5859 20.3267 14.2501 19.9125 14.2501H15.3532L16.1714 9.75013H21.0004C21.4146 9.75013 21.7504 9.41434 21.7504 9.00013C21.7504 8.58592 21.4146 8.25013 21.0004 8.25013H16.4441L17.2379 3.88429ZM13.8286 14.2501L14.6468 9.75013H10.1714L9.35321 14.2501H13.8286Z"
        fill={getPrimaryColor()}
      />
    </svg>
  )
}
