import styled from '@emotion/styled';
import tw from 'twin.macro';
import stylesDialog from '../../../../styles/dialog.module.scss';
import FastDeliveryIcon from 'public/assets/90-min.svg';
import { BuildingsIcon } from '@components/Atoms/Icons/BuildingsIcon';
import { HashIcon } from '@components/Atoms/Icons/HashIcon';
import { TruckIcon } from '@components/Atoms/Icons/TruckIcon';
import { UnionIcon } from '@components/Atoms/Icons/UnionIcon';
import { OrderResult } from '@app/checkout/types';
import {
  getDeliveryTimeHandler,
  getFullSelectedDateDescription,
} from './Delivery/utils';
import Image from 'next/image';

const orderConfirmImg = '/assets/images/orderConfirm.png';
import {
  Dialog,
  Button,
  ButtonVariantEnum,
} from '@teddly/teddly-ui-components';
import { useRoutesContext } from '@context/RoutesContext';
import moment from 'moment';
import { ids } from '../../../../../cypress/pages/checkout/CheckoutPage.cy';
import { DeliveryTypeEnum } from 'teddly-sdk';
import { useCheckoutFlowContext } from '@context/CheckoutFlowContext';
import { useState } from 'react';
import styles from './style.module.scss';
import { getPrimaryColor, getPrimaryColorAsHex } from '@utils';
import { css } from '@emotion/react';

const OrderInfo = styled.div({
  ...tw`flex `,
  flexDirection: 'column',
  gap: '6px',
});

const OrderInfoRow = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: minmax(10px, 30px) 1fr;
  gap: 12px;
  align-items: center;
`;

const OrderInfoValue = styled.span({
  ...tw`  font-semibold`,
  columnGap: '6px',
  fontWeight: 500,
  display: 'flex',
  flexWrap: 'wrap',
});
const OrderInfoValueDescription = styled.span({
  ...tw`  
  pr-1
  `,
  fontWeight: 600,
});

const ModalContentWrapper = styled.div({
  ...tw`flex `,
  background: '#f6f6f6',
});

export default function OrderConfirmation({
  orderResult,
  isOpen,
}: {
  orderResult: OrderResult;
  isOpen: boolean;
}) {
  const { router, routes, pages, navigateTo } = useRoutesContext();
  const { checkout } = useCheckoutFlowContext();
  const [trackOrderLoading, setTrackOrderLoading] = useState(false);
  const [keepShoppingLoading, setKeepShoppingLoading] = useState(false);

  const onTrackOrderClick = () => {
    setTrackOrderLoading(true);
    navigateTo({ route: `/order-details/${orderResult.id}` });
    setTrackOrderLoading(false);
  };
  const onKeepShoppingClick = () => {
    setKeepShoppingLoading(true);
    navigateTo({ route: pages.HOME });
    setKeepShoppingLoading(false);
  };

  return (
    <Dialog
      className={stylesDialog.orderConfirmationDialog}
      isOpen={isOpen}
      title={'Order confirmation'}
      onClose={() => router.replace(routes[pages.HOME])}
      disableBackdropClick={false}
      disableEscapeKeyDown={false}
      footerProps={{
        children: [
          <Button
            key={'Keep-Shopping-button'}
            variant={'outline'}
            data-testid={ids.orderConfirmationKeepShoppingButton}
            type="submit"
            onClick={onKeepShoppingClick}
            loading={keepShoppingLoading}
            disabled={keepShoppingLoading || keepShoppingLoading}
            title={'Keep Shopping'}
          />,
          <Button
            key={'Track-order-button'}
            data-testid={ids.orderConfirmationTrackOrderButton}
            variant={ButtonVariantEnum.FILL}
            type="submit"
            loading={!!trackOrderLoading}
            disabled={keepShoppingLoading || keepShoppingLoading}
            onClick={onTrackOrderClick}
            title={'Track order'}
          />,
        ],
      }}>
      <Dialog.Body className={stylesDialog.dialogBody}>
        <div className={styles.orderConfirmDialogBodyContent}>
          <ModalContentWrapper>
            <Image
              objectFit="cover"
              layout="responsive"
              width={16}
              height={9}
              style={{ maxHeight: '310px' }}
              src={orderConfirmImg}
              alt={'order confirmation'}
            />
          </ModalContentWrapper>

          <div
            style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
            <div className={styles.orderConfirmTitle}>
              {`We've got your order!`}
            </div>
            <div
              className={styles.orderConfirmSubTitle}
              data-testid={ids.orderConfirmationOrderMessage}>
              {
                "Hold tight! A human is reviewing your order to make sure it's all good and ready for fulfillment.\nOnce it’s approved, we'll send an email with the order details."
              }
            </div>
          </div>
          <OrderInfo>
            <OrderInfoRow data-testid={ids.orderConfirmationOrderNumber}>
              <HashIcon class="stroke-3"></HashIcon>
              <OrderInfoValue>
                <OrderInfoValueDescription>
                  Order number:
                </OrderInfoValueDescription>
                {orderResult.number}
              </OrderInfoValue>
            </OrderInfoRow>

            <OrderInfoRow data-testid={ids.orderConfirmationOrderTotal}>
              <UnionIcon />
              <OrderInfoValue>
                <OrderInfoValueDescription>Total:</OrderInfoValueDescription>$
                {orderResult?.total?.gross?.amount}
              </OrderInfoValue>
            </OrderInfoRow>

            {orderResult?.timeslot?.deliveryType ==
              DeliveryTypeEnum.SHIPPING && (
              <OrderInfoRow data-testid={ids.orderConfirmationDeliveryAddress}>
                <BuildingsIcon fontSize="small" />
                <OrderInfoValue>
                  <OrderInfoValueDescription>
                    Address:
                  </OrderInfoValueDescription>
                  {`${orderResult?.shippingAddress?.streetAddress1}, ${orderResult?.shippingAddress?.postalCode}`}
                </OrderInfoValue>
              </OrderInfoRow>
            )}

            <OrderInfoRow data-testid={ids.orderConfirmationDeliveryTime}>
              {orderResult?.isFastDelivery ? (
                <FastDeliveryIcon />
              ) : (
                <TruckIcon fontSize="small" />
              )}
              <OrderInfoValue>
                <OrderInfoValueDescription>
                  {checkout?.deliveryType == DeliveryTypeEnum.PICK_UP
                    ? 'Pick-up time'
                    : 'Delivery time:'}
                </OrderInfoValueDescription>
                {orderResult?.timeslot
                  ? getDeliveryTimeHandler(
                      orderResult.isFastDelivery,
                      moment(orderResult.created),
                      orderResult.timeslot,
                    )
                  : 'As soon as possible...'}
              </OrderInfoValue>
            </OrderInfoRow>
          </OrderInfo>
        </div>
      </Dialog.Body>
    </Dialog>
  );
}
