import React, { useEffect, useState } from 'react';
import { useDeliveryContext } from '@context/DeliveryContext';
import DeliveryPicker from './DeliveryPicker';
import { ids as headerIds } from '../../../../../../cypress/pages/page-layout/HeaderPage.cy';
import { ids } from '../../../../../../cypress/pages/checkout/CheckoutPage.cy';
import { getFullSelectedDateDescription, getNowTimeTitle } from './utils';
import { useCheckoutShippingAddressContext } from '@context/CheckoutShippingAddressContext';
import {
  Button,
  ButtonVariantEnum,
  UserInfoBlock,
} from '@teddly/teddly-ui-components';
// import FastDeliveryIcon from 'public/assets/990-minutes-primary.svg';
import styles from './Delivery.module.scss';
import { useCheckoutFlowContext } from '@context/CheckoutFlowContext';
import { DeliveryTypeEnum } from 'teddly-sdk';
import { FastDeliveryIcon } from '@components/Atoms/Icons/FastDeliveryIcon';

interface DeliveryInCheckoutProps {
  title: string;
  onCloseHeaderDialog?: () => void;
}

export default function DeliveryInCheckout({
  title,
  onCloseHeaderDialog,
}: DeliveryInCheckoutProps) {
  const [isChange, setIsChange] = useState<boolean>();
  const {
    selectedTimeslot,
    isTimeslotNeeded,
    isTimeslotValid,
    setSelectedTimeslot,
  } = useDeliveryContext();
  const {
    isCheckoutShippingAddressValid,
    selectedShippingAddress,
    isShippingLoading,
  } = useCheckoutShippingAddressContext();
  const { checkout } = useCheckoutFlowContext();
  const onClick = () => {
    setIsChange(true);
    setSelectedTimeslot(null);
  };
  useEffect(() => {
    if (!isTimeslotNeeded) {
      setIsChange(false);
    }
  }, [isTimeslotNeeded]);

  if (
    !selectedShippingAddress &&
    checkout?.deliveryType == DeliveryTypeEnum.SHIPPING
  ) {
    return null;
  }
  const getDescription = () => {
    if (!isTimeslotNeeded)
      return `${checkout?.shippingMethod?.name}: As soon as possible`;
    if (
      !isTimeslotValid ||
      !isCheckoutShippingAddressValid ||
      !selectedTimeslot
    )
      return `Select timeslot`;
    if (checkout?.shippingMethod?.isFastDelivery) {
      return (
        <div className={styles.newTitle}>
          <FastDeliveryIcon />
          <div>{getNowTimeTitle(selectedTimeslot)}</div>
        </div>
      );
    }
    return `${checkout?.shippingMethod?.name}: ${getFullSelectedDateDescription(selectedTimeslot)}`;
  };
  const isLoading = () => {
    return (
      isShippingLoading ||
      !checkout?.availableShippingMethods?.find(
        (v) => v.id === checkout?.shippingMethod?.id,
      ) ||
      (checkout?.availableShippingMethods?.length > 1 &&
        checkout?.availableShippingMethods[
          checkout?.availableShippingMethods?.length - 1
        ].id === checkout?.shippingMethod?.id)
    );
  };
  return (!isChange && selectedTimeslot) ||
    !isCheckoutShippingAddressValid ||
    (!isTimeslotNeeded && !selectedTimeslot) ? (
    <UserInfoBlock
      isWithShading
      data-testid={ids.selectDeliveryDay}
      title={title}
      selected
      description={
        isLoading()
          ? `Getting ${checkout?.deliveryType == DeliveryTypeEnum.PICK_UP ? 'Pick up' : 'delivery'} time...`
          : getDescription()
      }
      buttons={
        !isLoading() &&
        (!isTimeslotValid ||
          !isCheckoutShippingAddressValid ||
          !selectedTimeslot ||
          !isTimeslotNeeded)
          ? []
          : [
              <Button
                key={'selectDeliveryDayChangeButton'}
                data-testid={ids.selectDeliveryDayChangeButton}
                loading={isLoading()}
                title="Change"
                variant={ButtonVariantEnum.OUTLINE}
                onClick={isTimeslotNeeded ? onClick : null}
              />,
            ]
      }
    />
  ) : (
    <DeliveryPicker
      onCloseHeaderDialog={onCloseHeaderDialog}
      setIsChanging={setIsChange}
    />
  );
}
